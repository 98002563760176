const merge = require('lodash.merge');
const Joi = require('joi');

const { schema } = require('./schema');

// Parcel does not support dynamic requires
const devConfig = require('./dev.config');
const testConfig = require('./test.config');
const prodConfig = require('./production.config');

const configs = {
  dev: devConfig,
  test: testConfig,
  production: prodConfig
};

const getConfigForEnvironment = environment => {
  const currentConfig = configs[environment] || {};
  const localConfig = {
    AUTH0_CLIENT_ID: process.env.REACT_APP_AUTH0_CLIENT_ID,
    AUTH0_DOMAIN: process.env.REACT_APP_AUTH0_DOMAIN,
    AUTH0_CUSTOM_DOMAIN: process.env.REACT_APP_AUTH0_CUSTOM_DOMAIN,
    AUTH0_API_AUDIENCE: process.env.REACT_APP_AUTH0_API_AUDIENCE,
    GRAPHQL_API_ENDPOINT: process.env.REACT_APP_GRAPHQL_API_ENDPOINT,
    DOMAIN: process.env.REACT_APP_DOMAIN,
    SPLIT_API_KEY: process.env.REACT_APP_SPLIT_API_KEY,
    ALGOLIA_STAGE: process.env.REACT_APP_ALGOLIA_STAGE,
    ALGOLIA_SEARCH_ONLY_API_KEY: process.env.REACT_APP_ALGOLIA_SEARCH_ONLY_API_KEY,
    STAGE: environment
  };

  const config = merge(
    require('./common.config.js'),
    localConfig,
    currentConfig
  );

  const { error, value } = Joi.validate(config, schema);

  if (error) {
    throw error;
  }

  return value;
};

module.exports = { getConfigForEnvironment };
