module.exports = {
  AUTH0_CLIENT_ID: 'NFaONQyTdtn370azYapnCM3hl5lAbyqb',
  AUTH0_DOMAIN: 'staging-acloudguru.auth0.com',
  AUTH0_CUSTOM_DOMAIN: 'staging-acloudguru.auth0.com',
  AUTH0_API_AUDIENCE: 'https://staging-acloudguru.auth0.com/api/v2/',
  DOMAIN: 'http://localhost:3000/',
  GRAPHQL_API_ENDPOINT: 'https://test-api.acloud.guru/bff/graphql',
  SPLIT_API_KEY: '431h277t9p9grmcek9huno28s3b2ki0bp4f2',
  ALGOLIA_STAGE: 'test',
  ALGOLIA_SEARCH_ONLY_API_KEY: '244df520f0ab19af76ed4ae40ce9a1ef'
};
